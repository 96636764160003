import { render } from "./Checkout.vue?vue&type=template&id=858ae232&scoped=true"
import script from "./Checkout.vue?vue&type=script&lang=js"
export * from "./Checkout.vue?vue&type=script&lang=js"

import "./Checkout.vue?vue&type=style&index=0&id=858ae232&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-858ae232"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QImg,QCardSection});
